let parsedCustomerPreference;

(function checkIfBodyIsAvailable()
{
    if (document.body) {
        return init();
    }
    window.requestAnimationFrame(checkIfBodyIsAvailable);
})();

(function checkIfTaxSwitcherIsAvailable()
{
    const taxSwitcherValueExcSelector = 'vat-switcher__value value-excluding';
    if (document.body && document.body.getElementsByClassName(taxSwitcherValueExcSelector)[0]) {
        return showTaxSwitcher();
    }
    window.requestAnimationFrame(checkIfTaxSwitcherIsAvailable);
})();

function showTaxSwitcher()
{
    const checkedSwitcherClass = 'header__vat-switcher--checked',
        taxSwitcherSelector = 'vat-switcher header__vat-switcher',
        taxSwitcherValueIncSelector = 'vat-switcher__value value-including',
        taxSwitcherValueExcSelector = 'vat-switcher__value value-excluding',
        taxSwitcherElement = document.body.getElementsByClassName(taxSwitcherSelector)[1],
        taxValueExclElement = document.body.getElementsByClassName(taxSwitcherValueExcSelector)[0],
        taxValueInclElement = document.body.getElementsByClassName(taxSwitcherValueIncSelector)[0];
    let taxState = '';
    if (parsedCustomerPreference) {
        taxState = parsedCustomerPreference.customer_tax_display;
    }
    if (taxState === 'including') {
        if (taxSwitcherElement) {
            taxSwitcherElement.classList.add(checkedSwitcherClass);
        }
        if (taxValueExclElement) {
            taxValueExclElement.classList.add('hidden');
        }
        if (taxValueInclElement) {
            taxValueInclElement.classList.remove('hidden');
        }
    } else {
        if (taxSwitcherElement) {
            taxSwitcherElement.classList.remove(checkedSwitcherClass);
        }
        if (taxValueExclElement) {
            taxValueExclElement.classList.remove('hidden');
        }
        if (taxValueInclElement) {
            taxValueInclElement.classList.add('hidden');
        }
    }
}

function getCustomerPreference()
{
    const cacheStorage = localStorage.getItem('mage-cache-storage');
    if (!cacheStorage) {
        return null;
    }
    const parsedCache = JSON.parse(cacheStorage);
    if (!parsedCache || !parsedCache.hasOwnProperty('customer-preference')) {
        return null;
    }
    return parsedCache['customer-preference'];
}

function init()
{
    var parsedPreference = getCustomerPreference(),
        includeClass = 'tax-display-including',
        excludeClass = 'tax-display-excluding',
        taxState = '';

    var search = window.location.search;
    if (search && /(^\?|&)qcd=/.test(search)) {
        taxState = 'including';
    }
    else if (parsedPreference) {
        taxState = parsedPreference.customer_tax_display;
    }

    if (taxState === 'including') {
        document.body.classList.remove(excludeClass);
        document.body.classList.add(includeClass, 'tax-display-initialized');
    } else if (taxState === 'excluding') {
        document.body.classList.remove(includeClass);
        document.body.classList.add(excludeClass, 'tax-display-initialized');
    } else {
        document.body.classList.add('tax-display-initialized');
    }
}
